
import React from 'react';
import "./carousel-landing.scss";
import CAROUSEL_2 from "../../../../assets/images/carousel/carousel-2.png";
import CAROUSEL_3 from "../../../../assets/images/carousel/carousel-3.png";
import CAROUSEL_4 from "../../../../assets/images/carousel/carousel-4.png";
import CAROUSEL_5 from "../../../../assets/images/carousel/carousel-5.png";
import CAROUSEL_6 from "../../../../assets/images/carousel/carousel-6.png";
import CAROUSEL_7 from "../../../../assets/images/carousel/carousel-7.png";
import CarouselModel from "../../../../model/carousel/carousel.model";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';

const CarouselLandingComponent = () => {

    const props: CarouselModel[] = [
        {
            img: CAROUSEL_2,
            title: "Hunt for talent like a lion",
            desc: "powerful, strategic, and king of the jungle!"
        },
        {
            img: CAROUSEL_3,
            title: "From Sluggish Snail to Speedy Cheetah",
            desc: "Turbocharge Your Job Description Creation!"
        },
        {
            img: CAROUSEL_4,
            title: "Eagle-eyed precision",
            desc: "for your job description spot issues in the boring writing"
        },
        {
            img: CAROUSEL_5,
            title: "Social Media Generator is like a peacock flaunting its feathers,",
            desc: "Turning heads and attracting candidates with  its dazzling display!"
        },
        {
            img: CAROUSEL_6,
            title: "Think of it as a dolphin sifting throughthe ocean",
            desc: "Resume Pre-Screening elegantly separates  the fish from the seaweed in the job sea!"
        },
        {
            img: CAROUSEL_7,
            title: "It's like a wise old elephant remembering every detail",
            desc: "our Interview Questions Generator never forgets to ask the critical questions!"
        }
    ]
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        initialSlide: 0,
        adaptiveHeight: true,
    };

    return (
        <div className="carousel-landing-control">
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                totalSlides={props.length}
                orientation={'vertical'}
                isPlaying={true} // Enable autoplay
                interval={3000} // Slide transition every 3000ms (3 seconds)
                preventVerticalScrollOnTouch={true} // Autoplay will pause when the mouse hovers over the carousel
                infinite={true} // Enables infinite loop sliding
            >
                <Slider>
                    {props.map((item, index) => (
                        <Slide index={index} key={index}>
                            <div className="carousel-landing-item-control"
                            >
                                <img src={item.img} className="carousel-landing-img" />
                                <div className="carousel-landing-item-text-control">
                                    <span className="carousel-landing-item-title">
                                        {item.title}
                                    </span>
                                    <span className="carousel-landing-item-desc">
                                        {item.desc}
                                    </span>
                                </div>
                            </div>
                        </Slide>
                    ))}
                </Slider>
                <DotGroup className='carousel-dots-control' />
            </CarouselProvider>
        </div>
    );
}

export default CarouselLandingComponent;