import { Box } from "@mui/material";
import TalentSauceHeaderComponent from "./header/talent-sauce-header";
import "./talent-sauce-landing.scss";
import IMAGE_T from "../../../assets/images/techsauce/T.png";
import IMAGE_S from "../../../assets/images/techsauce/S.png";

import { Button } from "react-bootstrap";
import TalentSauceFooterComponent from "./footer/talent-sauce-footer";
import COMPANY_LOGO from "../../../assets/images/techsauce/company-logo.png";
import IMAGE_ASSET_1 from "../../../assets/images/techsauce/landing-asset-1.png";
import IMAGE_ASSET_2 from "../../../assets/images/techsauce/landing-asset-2.png";
import IMAGE_ASSET_3 from "../../../assets/images/techsauce/landing-asset-3.png";
import IMAGE_WAVE_SECTION_2 from "../../../assets/images/techsauce/landing-wave-section-2.png";
import IMAGE_WAVE_SECTION_3_1 from "../../../assets/images/techsauce/landing-wave-section-3-1.png";
import IMAGE_WAVE_SECTION_3_2 from "../../../assets/images/techsauce/landing-wave-section-3-2.png";
import IMAGE_LOGO from "../../../assets/images/techsauce/logo-talent-sauce.png";
import { useNavigate } from "react-router-dom";
import UtilityService from "../../../utils/utilities";
import { useLanguage } from "../../../context/language.context";
import { useEffect, useState } from "react";
import languageService from "../../../services/language.service";
import JSON_LANG_TH from "../../../assets/json/talent-sauce/landing-th.json";
import JSON_LANG_EN from "../../../assets/json/talent-sauce/landing-en.json";
import LINE_WAVE from "../../../assets/images/techsauce/line-wave.png";
import CURVE_WAVE from "../../../assets/images/techsauce/curve-wave.png";
import SO_LOGO from "../../../assets/images/techsauce/so-logo.png";
import TS_LOGO from "../../../assets/images/techsauce/ts-logo.png";
import CarouselLandingComponent from "./carousel-landing/carousel-landing";
import ArticlesComponent from "./articles/articles";
import config from "../../../config";
import IMG_PRODUCT_1 from "../../../assets/images/techsauce/landing-solu-1.png";
import IMG_PRODUCT_2 from "../../../assets/images/techsauce/landing-solu-2.png";
import IMG_PRODUCT_3 from "../../../assets/images/techsauce/landing-solu-3.png";
import IMG_PRODUCT_4 from "../../../assets/images/techsauce/landing-solu-4.png";
import IMG_PRODUCT_TH_1 from "../../../assets/images/techsauce/landing-solu-th-1.png";
import IMG_PRODUCT_TH_2 from "../../../assets/images/techsauce/landing-solu-th-2.png";
import IMG_PRODUCT_TH_3 from "../../../assets/images/techsauce/landing-solu-th-3.png";
import IMG_PRODUCT_TH_4 from "../../../assets/images/techsauce/landing-solu-th-4.png";

const TalentSauceLandingComponent = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [translate, setTranslate] = useState(JSON_LANG_EN)
    useEffect(() => {
        if (languageService.getLanguage() == 'en') {
            setTranslate(JSON_LANG_EN)
        } else {
            setTranslate(JSON_LANG_TH)
        }
        window.onload = function () {
            window.scrollTo(0, 0);
        };
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        if (language) {
            console.log(language);
            if (language == 'en') {
                setTranslate(JSON_LANG_EN)
            } else {
                setTranslate(JSON_LANG_TH)
            }
            window.onload = function () {
                window.scrollTo(0, 0);
            };
            window.scrollTo(0, 0);
        }

    }, [language])
    const clickGetStart = () => {
        UtilityService().clickSendEvent("landing_try_free", "click");
        navigate("/preview");

    }
    const clickGetPricing = () => {
        UtilityService().clickSendEvent("landing_pricing", "click");
        navigate("/pricing");

    }
    const clickContact = () => {

        UtilityService().clickSendEvent("landing_contact_us", "click");
        window.open("https://docs.google.com/forms/d/e/1FAIpQLScNusrvXlQJwVKB2jl-e6_FXexXoWZkhmpA09U7VK8a5fo0mQ/viewform", "_blank");
    }

    const clickSO = () => {
        UtilityService().clickSendEvent("scoutout_logo", "click");
        window.open("https://scoutout.co?utm_source=talentsauce_landing")
    }
    const clickTS = () => {
        UtilityService().clickSendEvent("techsauce_logo", "click");
        window.open("https://techsauce.co?utm_source=talentsauce_landing")
    }
    const clickLINEOA = () => {
        UtilityService().clickSendEvent("landing_click_line_oa", "click");
        window.open("https://lin.ee/HwNciwt", "_blank")
    }

    const findTalent = () => {
        UtilityService().clickSendEvent("find_talent", "click");
        navigate("/preview");
    }
    const findSolution = () => {
        UtilityService().clickSendEvent("find_solutions", "click");
        navigate("/pricing");
    }
    const findService = () => {
        UtilityService().clickSendEvent("find_service", "click");
        navigate("/pricing");
    }
    const chatWithUs = (section) => {
        UtilityService().clickSendEvent("chat_with_us_" + section, "click");
        window.open("https://lin.ee/HwNciwt", "_blank")
    }
    const joinCommunity = () => {
        UtilityService().clickSendEvent("join_community", "click");
        window.open("https://lin.ee/HwNciwt", "_blank")
    }

    const dropResume = () => {
        UtilityService().clickSendEvent("drop_resume", "click");
        window.open(config.app.one_profile + "guest/talent-sauce/drop-resume", "blank");
    }

    const prepareYourSelf = () => {
        UtilityService().clickSendEvent("prepare_your_self", "click");
        window.open(config.app.one_profile + "guest/talent-sauce/drop-resume", "blank");
    }

    const clickImgProduct = (index) => {
        UtilityService().clickSendEvent("landing_product_solution_" + index, "click");
        navigate("/pricing");
    }
    return (
        <>
            <div className="talent-sauce-landing-main">
                <div className="talent-sauce-landing-overlay-black"></div>
                <div className="talent-sauce-landing-cover-header-control">
                    <div className="talent-sauce-landing-cover-img"></div>
                    <TalentSauceHeaderComponent></TalentSauceHeaderComponent>
                    {/* <img src={IMAGE_T} className="talent-sauce-landing-cover-t"></img>
                    <img src={IMAGE_S} className="talent-sauce-landing-cover-s"  ></img> */}
                    <div className="talent-sauce-landing-cover-grid-control">
                        <div className="talent-sauce-landing-cover-header-text-control">

                            <h1 className="talent-sauce-landing-cover-header-text-white-header">
                                {translate.cover_section.header_line_2}
                            </h1>
                            <h1 className="talent-sauce-landing-cover-header-text-white-header-2">{translate.cover_section.header_line_2_1}</h1>
                            <h1 className="talent-sauce-landing-cover-header-textsub-gray">{translate.cover_section.header_cover_sub_gray}</h1>
                            <div className="talent-sauce-landing-cover-logo-control">
                                <img src={TS_LOGO} className="talent-sauce-landing-cover-logo" onClick={clickTS}></img>
                                <img src={SO_LOGO} className="talent-sauce-landing-cover-logo" onClick={clickSO}></img>
                            </div>
                            <div className="talent-sauce-carousel-mobile-control">
                                <CarouselLandingComponent ></CarouselLandingComponent>
                            </div>
                            <span className="talent-sauce-landing-cover-header-textsub-gray">{translate.cover_section.special_offer}</span>
                            <div className="talent-sauce-landing-cover-button-control">
                                <Button className="talent-suace-landing-cover-button-pricing" onClick={clickGetPricing}>{translate.cover_section.button_1}</Button>
                                <Button className="talent-suace-landing-cover-button-start" onClick={clickGetStart}>{translate.cover_section.button_2}</Button>

                            </div>

                            <div className="talent-sauce-landing-contact-control">

                                <span className="talent-sauce-landing-contact-text">
                                    {translate.cover_section.contact}
                                </span>
                                <span className="talent-sauce-landing-contact-text">
                                    {translate.cover_section.contact_1}
                                    <span className="talent-sauce-landing-contact-text-orange" onClick={clickContact}>{translate.contact_us.title_orange}</span>
                                    {translate.cover_section.contact_2}
                                    <span className="talent-sauce-landing-contact-text-orange" onClick={clickLINEOA}>{translate.contact_us.title_add_line}</span>
                                </span>
                            </div>
                        </div>
                        <div className="talent-sauce-carousel-control">
                            <CarouselLandingComponent ></CarouselLandingComponent>
                        </div>
                    </div>
                </div>

                <div className="talent-sauce-landing-section-3-control">
                    {
                        languageService.getLanguage() == 'en' ?

                            <div className="talent-sauce-landing-section-3-product-control">
                                <img src={IMG_PRODUCT_1}
                                    onClick={() => clickImgProduct("1")}
                                    className="talent-sauce-landing-section-3-product-img"></img>
                                <img src={IMG_PRODUCT_2}
                                    onClick={() => clickImgProduct("2")}
                                    className="talent-sauce-landing-section-3-product-img-2"></img>
                                <img src={IMG_PRODUCT_3}
                                    onClick={() => clickImgProduct("3")}
                                    className="talent-sauce-landing-section-3-product-img-2"></img>
                                <img src={IMG_PRODUCT_4}
                                    onClick={() => clickImgProduct("4")}
                                    className="talent-sauce-landing-section-3-product-img"></img>
                            </div> : 
                             <div className="talent-sauce-landing-section-3-product-control">
                             <img src={IMG_PRODUCT_TH_1}
                                 onClick={() => clickImgProduct("1")}
                                 className="talent-sauce-landing-section-3-product-img"></img>
                             <img src={IMG_PRODUCT_TH_2}
                                 onClick={() => clickImgProduct("2")}
                                 className="talent-sauce-landing-section-3-product-img-2"></img>
                             <img src={IMG_PRODUCT_TH_3}
                                 onClick={() => clickImgProduct("3")}
                                 className="talent-sauce-landing-section-3-product-img-2"></img>
                             <img src={IMG_PRODUCT_TH_4}
                                 onClick={() => clickImgProduct("4")}
                                 className="talent-sauce-landing-section-3-product-img"></img>
                         </div>
                    }
                    <div className="talent-sauce-landing-section-3-grid-left">
                        <div className="talent-sauce-landing-section-3-grid-img-control">
                            <img src={IMAGE_ASSET_1} className="talent-sauce-landing-section-3-grid-img"></img>
                        </div>
                        <div className="talent-sauce-landing-section-3-text-control">

                            <span className="talent-sauce-landing-section-3-text-white-header">
                                {translate.section_2.section_2_1.title}<span className="talent-sauce-landing-section-3-text-orange-header">{translate.section_2.section_2_1.title_orange}</span>
                            </span>
                            <span className="talent-sauce-landing-section-3-desc-text-white">
                                {translate.section_2.section_2_1.description}
                            </span>
                            <div className="talent-sauce-button-flex-control">
                                <Button className="talent-suace-landing-orange" onClick={findTalent}>{translate.section_2.find_talent}</Button>
                                <Button className="talent-suace-landing-orange-border" onClick={() => chatWithUs("2_1")}>{translate.section_2.chat_with_us}</Button>
                            </div>

                        </div>
                    </div>
                    <div className="talent-sauce-landing-section-3-grid-right">
                        <img src={IMAGE_WAVE_SECTION_3_1} className="d-md-flex d-none talent-sauce-landing-section-3-wave-1"></img>
                        <div className="talent-sauce-landing-section-3-text-control order-1 order-md-0">

                            <span className="talent-sauce-landing-section-3-text-white-header">
                                {translate.section_2.section_2_2.title} <br></br>

                                {translate.section_2.section_2_2.title_2} <span className="talent-sauce-landing-section-3-text-orange-header">{translate.section_2.section_2_2.title_orange}</span>
                            </span>
                            <span className="talent-sauce-landing-section-3-desc-text-white">
                                {translate.section_2.section_2_2.description}
                            </span>
                            <div className="talent-sauce-button-flex-control">
                                <Button className="talent-suace-landing-orange" onClick={findSolution}>{translate.section_2.find_solution}</Button>
                                <Button className="talent-suace-landing-orange-border" onClick={() => chatWithUs("2_2")}>{translate.section_2.chat_with_us}</Button>
                            </div>
                        </div>
                        <div className="talent-sauce-landing-section-3-grid-img-control order-0 order-md-1">
                            <img src={IMAGE_ASSET_2} className="talent-sauce-landing-section-3-grid-img"></img>
                        </div>

                    </div>
                    <div className="talent-sauce-landing-section-3-grid-left">
                        <div className="talent-sauce-landing-section-3-grid-img-control">

                            <img src={IMAGE_ASSET_3} className="talent-sauce-landing-section-3-grid-img"></img>
                        </div>
                        <div className="talent-sauce-landing-section-3-text-control">

                            <span className="talent-sauce-landing-section-3-text-white-header">
                                {translate.section_2.section_2_3.title} <br></br><span className="talent-sauce-landing-section-3-text-orange-header">{translate.section_2.section_2_3.title_orange}</span>
                            </span>

                            <span className="talent-sauce-landing-section-3-desc-text-white">
                                {translate.section_2.section_2_3.description}
                            </span>
                            <div className="talent-sauce-button-flex-control">
                                <Button className="talent-suace-landing-orange" onClick={joinCommunity}>{translate.section_2.join_our_club}</Button>
                                <Button className="talent-suace-landing-orange-border" onClick={findService}>{translate.section_2.find_more_service}</Button>
                            </div>
                        </div>
                        <img src={IMAGE_WAVE_SECTION_3_2} className="d-md-flex d-none talent-sauce-landing-section-3-wave-2"></img>
                    </div>
                </div>
                <div className="talent-sauce-landing-section-4-control">
                    <div className="talent-sauce-landing-section-5-text-control">
                        <div className="talent-sauce-landing-section-5-pricing-control">
                            <img src={LINE_WAVE} className="talent-sauce-landing-section-5-pricing-line-wave"></img>
                            <img src={CURVE_WAVE} className="talent-sauce-landing-section-5-pricing-curve-wave"></img>
                            <span className="talent-sauce-landing-section-5-pricing-text">
                                {translate.section_3.description}
                            </span>
                            <span className="talent-sauce-landing-section-5-pricing-special-offer">{translate.section_3.special_offer}</span>
                            <Button className="talent-suace-landing-cover-button-pricing mx-auto" onClick={clickGetPricing}>{translate.section_3.button}</Button>
                        </div>

                        <div className="talent-sauce-landing-section-5-company-control">
                        <span className="talent-sauce-landing-section-5-company-title">{translate.company.title}</span>
                        <img src={COMPANY_LOGO} className="talent-sauce-landing-section-5-company-img"></img>
                        </div>

                    </div>
                </div>
                <div className="talent-sauce-landing-section-5-control">
                    <img src={IMAGE_LOGO} className="talent-sauce-landing-logo"></img>
                    <div className="talent-sauce-landing-section-5-text-control">
                        <span className="talent-sauce-landing-section-5-text">

                            {translate.section_4.description}
                        </span>
                        <Button className="talent-suace-landing-cover-button-start mx-auto" onClick={clickGetStart}>{translate.section_4.button}</Button>
                        <div className="talent-sauce-landing-contact-control">

                            <span className="talent-sauce-landing-contact-text-bottom">
                                {translate.contact_us.title} <span className="talent-sauce-landing-contact-text-orange" onClick={clickContact}>{translate.contact_us.title_orange}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <ArticlesComponent></ArticlesComponent>
                <div className="talent-sauce-landing-section-2-control">

                    <div className="talent-sauce-landing-section-2-text-control">
                        <img src={IMAGE_WAVE_SECTION_2} className="talent-sauce-landing-section-2-img"></img>
                        <h2 className="talent-sauce-landing-section-2-header-text"> {translate.section_1.title}</h2>
                        <span className="talent-sauce-landing-section-2-desc-text"> {translate.section_1.description}</span>
                        <div className="talent-sauce-button-flex-control-custom">
                            <Button className="talent-suace-landing-orange" onClick={dropResume}>{translate.section_1.drop_resume}</Button>
                            <Button className="talent-suace-landing-orange-border" onClick={prepareYourSelf}>{translate.section_1.prepare_yourself}</Button>
                        </div>
                    </div>
                    <div className="talent-sauce-landing-last-wave-back"></div>
                    <div className="talent-sauce-landing-last-wave"></div>

                </div>
                <TalentSauceFooterComponent></TalentSauceFooterComponent>
            </div>
        </>
    )
}

export default TalentSauceLandingComponent;